<template>
  <section>
    <div class="d-flex align-center pr-2 justify-end mb-2" style="gap: 16px" v-if="esUCP">
        <v-btn 
          color="primary" 
          class="mr-4"
          @click.stop="abrirModalDocumentos"
        >
        <v-icon class="white--text mr-2">mdi mdi-file-document-plus-outline</v-icon>
        Cargar nuevo documento
      </v-btn>
    </div>
    <EmptyComponent :empty="processWithNoDocuments">
      <p v-if="documentsLoading" class="pb-0 mb-4 text-center">Cargando documentos...</p>
      <v-expansion-panels focusable>
        <v-expansion-panel
          v-for="(value, field) in list_docs_var"
          :key="value.id"
          v-if="value.length > 0"
        >
          <v-expansion-panel-header>{{ field }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              class="mt-7 mb-5"
              :headers="HEADERS_CONST"
              :items="value"
              item-key="id"
              :items-per-page="5"
            >
              <!-- Slot de acciones -->
              <template v-slot:[`item.user`]="{ item }">
                <template v-if="item['Usuario?.Empleado.Persona.nombre'] && item['Usuario?.Empleado.Persona.apellido']">
                  {{ item['Usuario?.Empleado.Persona.nombre'] }} {{ item['Usuario?.Empleado.Persona.apellido'] }}
                </template>
                  <span v-else>-</span>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDate(item.created_at) }}
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <div class="d-flex justify-center align-center" style="gap: 8px;">
                  <v-btn icon @click="DownloadFileFtn(item)">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <v-btn v-if="showDeleteButton" icon @click="confirmFileDeletion(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            <!-- Template de columna de visibilidad a proveedor -->
            <template v-slot:[`item.visibilidad_proveedor`]="{ item }">
              <template v-if="esAuditoriaProceso">
                <span>{{ item.visibilidad_proveedor ? 'Visible' : 'No visible' }}</span>
              </template>
              <template v-else>
                <v-switch
                  color="success"
                  :ripple="false"
                  v-model="item.visibilidad_proveedor"
                  @change="cambiarVisibilidad($event, item.id)"
                  @click.stop
                  v-if="field !== 'Entrega de solicitud de oferta' && field !== 'Entrega de solicitud de propuesta'"
                  >
                  <template #prepend>
                    <v-label class="pl-6">{{ item.visibilidad_proveedor ? 'Visible' : 'No visible' }}</v-label>
                  </template>
                </v-switch>
                <span v-else>-</span>
              </template>
            </template>
            <template v-slot:[`item.publico`]="{ item }">
              <span v-if="item.update_publico">{{ moment(item.update_publico).format("DD/MM/YYYY hh:mm A") }} </span>
              <span v-else>-</span>
            </template>
            <template v-slot:[`item.privado`]="{ item }">
              <span v-if="item.update_privado">{{ moment(item.update_privado).format("DD/MM/YYYY hh:mm A") }} </span>
              <span v-else>-</span>
            </template>
            <template v-slot:[`item.enviar_correo`]="{ item }">
              <v-btn icon @click="confirmarEnvioDocumento(item)">
                <v-icon>mdi mdi-file-send</v-icon>
              </v-btn>
            </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </EmptyComponent>
    <ConfirmationModalComponent 
      :is-open="confirmationOpen"
      :is-loading="deleteLoading"
      description="¿Desea eliminar este documento?"
      @confirm="handleFileDelete" 
      @cancel="confirmationOpen = false"
    />
    <ConfirmationModalComponent 
      :is-open="confirmarEnvio"
      :is-loading="enviarDocumento.isLoading"
      :description="`¿Desea enviar el documento ${nombreDocumento} para notificarlo de su publicación por correo electrónico?`"
      @confirm="confirmarEnvioCorreo" 
      @cancel="confirmarEnvio = false"
    />
    <ModalCargarDocumentos
        :is-open="modalDocumento"
        @close="modalDocumento = false"
    />
  </section>
</template>

<script>
import moment from "moment";
import { ConfirmationModalComponent, EmptyComponent, SpinnerComponent } from '@/components/utils';
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import ModalCargarDocumentos from '@/views/ProcesoCompraDoc/components/ModalCargarDocumentos.vue'
import { Listener, Observable } from "@/utils/observable";


export default {
  components: { ConfirmationModalComponent, EmptyComponent, SpinnerComponent, ModalCargarDocumentos },
  data: () => ({
    // constantes
    HEADERS_CONST: [],

    // variables
    documentsLoading: false,
    proceso_publico: false,
    tecnico_asignado: false,
    rolJefeUcp: false,
    visibilidad_proveedor: false,
    cargandoVisibilidad: createLoadable([]),
    enviarDocumento: createLoadable([]),
    nombreDocumento: null,
    list_docs_var: [],
    // Eliminacion de documentos
    confirmationOpen: false,
    confirmarEnvio: false,
    activeDocument: null,
    deleteLoading: false,
    modalDocumento: false,
  }),
  computed: {
    showDeleteButton() {
      return this.haveRole("ROLE_ELIMINACION_DOCUMENTOS_PROCESO_VIEW");
    },
    processWithNoDocuments() {
      return !this.documentsLoading && this.list_docs_var.length === 0;
    },
    esUCP() {
      return this.haveRoles(["ROLE_UACI","ROLE_UACI_TECNICO"]);
    },
    esAuditoriaProceso() {
      return this.haveRole("ROLE_AUDITORIA_PROCESO");
    },
  },
  methods: {
    abrirModalDocumentos(){
      this.modalDocumento = true;
    },
    async DownloadFileFtn(item) {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: item.ruta_documento,
        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], item.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.setAttribute("download", item.nombre_documento);
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },

    async FetchListDocs() {
      try {
        this.documentsLoading = true;
        const RESP_SC_CONST = await this.services.PacProcesos.getListDocuments(
          this.$route.params.idProceso
        );
        if (RESP_SC_CONST) {
          const { data } = RESP_SC_CONST;
          this.list_docs_var = data;
          this.tecnico_asignado = data.empleado_asignado;
          this.setHeadersBasedOnRole();
        }
      } catch (error) {
        this.pushAppMessage({ message: 'Error al cargar los documentos.', type: 'error' });
      } finally {
        this.documentsLoading = false;
      }
    },
    confirmFileDeletion(document) {
      this.activeDocument = document;
      this.confirmationOpen = true;
    },
    async handleFileDelete() {
      this.confirmationOpen = false;
      try {
        this.deleteLoading = true;
        const response = await this.services.PacProcesos.deleteDocumentoProceso(this.activeDocument.id);

        if (response.status === 200) {
          this.pushAppMessage({ message: 'Documento eliminado correctamente.', type: 'success' });
          this.FetchListDocs();
        }

      } catch (error) {
        this.pushAppMessage({ message: 'Error al eliminar el documento', type: 'error' });
      } finally {
        this.deleteLoading = false;
      }
    },

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm A");
    },
    //Cambio de estado para visibilidad del proveedor en los documentos
    async cambiarVisibilidad(visibilidad_proveedor, documentoId) {
      toggleLoadable(this.cargandoVisibilidad);
      const { data } = await this.services.PacProcesos.estadoDocumentoProveedor(documentoId, { visibilidad_proveedor });
      
      setLoadableResponse(this.cargandoVisibilidad, data, { skipOnSuccess: true, showAlertOnSuccess: true });
      this.modalEstadoPublico = false;
      
      if (!isResponseSuccesful(data)) return;
    },

    async enviarCorreoDocumento(documentoId) {
      toggleLoadable(this.enviarDocumento);
      const idProceso =  this.$route.params.idProceso
      const { data } = await this.services.PacProcesos.enviarDocumentoProveedor(documentoId, idProceso);
      
      setLoadableResponse(this.enviarDocumento, data, { skipOnSuccess: true, showAlertOnSuccess: true });
      this.modalEstadoPublico = false;
      
      if (!isResponseSuccesful(data)) return;
    },

    confirmarEnvioDocumento(documento){
      this.documentoId = documento.id;
      this.nombreDocumento = documento.nombre_documento;
      if(!documento.visibilidad_proveedor) return this.temporalAlert({
          show: true,
          message: "Se debe habilitar la visibilidad al proveedor para enviar la notificación",
          type: "error",
      });
      this.confirmarEnvio = true;
    },

    async confirmarEnvioCorreo() {
      await this.enviarCorreoDocumento(this.documentoId);
      this.confirmarEnvio = false;
    },

    validarRol() {
      if (this.haveRole('ROLE_UACI')) {
        this.rolJefeUcp = true
      }
    },
    //Headers
    setHeadersBasedOnRole() {
      if (this.tecnico_asignado || this.rolJefeUcp || this.esAuditoriaProceso) {
        this.HEADERS_CONST = [
          {
            align: 'center',
            text: "Documento",
            value: "nombre_documento",
          },
          {
            align: 'center',
            text: "Subido por",
            value: "user",
          },
          {
            align: 'center',
            text: "Fecha de subida",
            value: "created_at",
          },
          {
            align: 'center',
            text: "Acciones",
            value: "acciones",
            sortable: false,
          },
          {
            align: 'center',
            text: "Visibilidad de proveedor",
            value: "visibilidad_proveedor",
          }
        ];
        if (this.esAuditoriaProceso) {
          this.HEADERS_CONST.push({
            align: 'center',
            text: "Última vez publicado",
            value: "publico",
          });
          this.HEADERS_CONST.push({
            align: 'center',
            text: "Última vez en privado",
            value: "privado",
          });
        }
        if (!this.esAuditoriaProceso) {
          this.HEADERS_CONST.push({
            align: 'center',
            text: "Enviar documento al proveedor",
            value: "enviar_correo",
          });
        }
      } else {
        this.HEADERS_CONST = [
          {
            align: 'center',
            text: "Documento",
            value: "nombre_documento",
          },
          {
            align: 'center',
            text: "Fecha de subida",
            value: "created_at",
          },
          {
            align: 'center',
            text: "Acciones",
            value: "acciones",
            sortable: false,
          },
        ];
      }
    },
  },
  created() {
    this.FetchListDocs();
    this.validarRol();
  },
  mounted() {
    const listener = new Listener('documentos').setHandler(() => this.FetchListDocs());
    this.unsubscribe = Observable.getInstance().subscribe('refresh-documentos', listener);
  }
};
</script>

<style>
</style>