<template>
  <section class="mb-16" fluid>
    <v-row class="px-4">
      <v-col cols="12" sm="6" md="7" lg="8">
        <v-row no-gutters>
          <v-col cols="12">
            <p class="font-weight-bold mb-0">Nombre del proceso:</p>
            <p class="">{{ procesoData?.nombre_proceso }}</p>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <p class="font-weight-bold mb-0">Modalidad de compra:</p>
            <p class="">{{ procesoData?.FormaContratacion?.nombre }}</p>
          </v-col>
        </v-row>

        <v-row no-gutters v-if="!haveRoles(['ROLE_PROVEEDOR'])">
          <v-col cols="12">
            <p class="font-weight-bold mb-0">Técnico asignado:</p>
            <p class="">
              {{ procesoData?.Empleado?.Persona?.primer_nombre }}
              {{ procesoData?.Empleado?.Persona?.segundo_nombre }}
              {{ procesoData?.Empleado?.Persona?.tercer_nombre }}
              {{ procesoData?.Empleado?.Persona?.primer_apellido }}
              {{ procesoData?.Empleado?.Persona?.segundo_apellido }}
              {{ procesoData?.Empleado?.Persona?.apellido_casada }}
            </p>
          </v-col>
        </v-row>

        <v-row no-gutters class="mb-4">
          <v-col cols="12">
            <p class="font-weight-bold">Mes/año inicio del proceso :</p>
            <h6 class="text-h6">{{ moment(picker, 'YYYY-MM').format('MM-YYYY') }}</h6>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <p class="font-weight-bold">Mes/año posible contratación :</p>
            <h6 class="text-h6">{{ moment(picker2, 'YYYY-MM').format('MM-YYYY') }}</h6>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="5" lg="4"
        v-if="haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])">
        <!-- <v-row class="mt-2">
          <v-col cols="12" sm="12" md="12">
            <v-alert border="left" type="info" elevation="2" colored-border>Este documento solo se puede subir una
              vez</v-alert>
          </v-col>
        </v-row> -->
        <div>
          <v-row>
            <v-col cols="12" sm="9" md="8">
              <v-file-input label="Expediente de proceso" outlined prepend-icon="" prepend-inner-icon="mdi-paperclip"
                accept="application/pdf" v-model="expediente" :error-messages="expedienteError" dense
                @blur="$v.expediente.$touch()" @input="$v.expediente.$touch()"></v-file-input>
            </v-col>
            <v-col cols="12" sm="3" md="4">
              <v-btn :color="documentos.length > 0 ? 'success' : 'secondary'" @click="cargar()" block class="py-5">
                {{ documentos.length > 0 ? 'Editar' : 'Cargar' }}
              </v-btn>
            </v-col>
          </v-row>

          <v-progress-linear
            v-if="showUploadProgress"
            v-model="uploadProgress"
            color="secondary"
            height="25"
            rounded
          >
            <template v-slot:default="{ value }">
              <p class="text-center white--text mb-0">{{ value }} %</p>
            </template>
          </v-progress-linear>
        </div>

        <v-row v-if="documentos.length > 0">
          <v-col>
            <v-card @click="descargarArchivo">
              <v-card-text class="text-center py-6">
                <p class="mb-0 secondary--text"><v-icon color="secondary">mdi-download</v-icon> Descargar expediente de
                  proceso</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import LegacyValidations from "@/utils/legacy-validations";
import { mapState } from "vuex";
import moment from "moment";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "InfoGeneralComponent",
  data: () => ({
    expediente: null,
    documentos: [],
    showUploadProgress: false,
    uploadProgress: 0,
  }),
  validations: {
    expediente: {
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    picker() {
      return moment(this.procesoData?.fecha_inicio_proceso).format("YYYY-MM");
    },
    picker2() {
      return moment(this.procesoData?.fecha_contratacion).format("YYYY-MM");
    },
    expedienteError() {
      const errors = [];
      if (!this.$v.expediente.$dirty) return errors;
      !this.$v.expediente.fileTypeValid &&
        errors.push("Solo se permiten archivos PDF");
      !this.$v.expediente.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      !this.$v.expediente.fileNameValid &&
        errors.push("El nombre del archivo no debe contener caracteres especiales");
      return errors;
    },
  },
  methods: {
    async downloadCdp() {

      const { status, data, headers } =
        await this.services.Usuarios.descargarTitulo({
          ruta: this.procesoData.documento_cdp,
          disk: "procesos",
        })

      if (status === 200) {
        const blob = new Blob([data], {
          type: headers["content-type"],
        });

        const file = new File(
          [blob],
          `tituloNombramiento_${this.procesoData.id}`,
          {
            type: headers["content-type"],
          }
        );

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "";
        link.click();
      }


    },
    async getExpedienteProceso() {
      let response2 = await this.services.PacProcesos.obtenerDocumentosProceso(
        this.$route.params.idProceso,
        5000,
      );

      if (response2?.status === 200) {
        this.documentos = response2?.data
        this.documento_cargado = this.documentos[0]?.ruta_documento
      }
    },
    async cargar() {
      try {
        this.$v.$touch()

        const formData = new FormData()
        formData.append("documento", this.expediente)
        formData.append("nombre", "Expediente de proceso")
        formData.append("id_proceso", this.$route.params.idProceso)
        formData.append("id_tipo_documento", 5000)
        formData.append("folder", "expediente_proceso")

        this.showUploadProgress = true;
        const response = await this.services.PacProcesos.guardarDocumentosProceso(
          formData,
          onUploadProgress => {
            this.uploadProgress = Math.round(
              (onUploadProgress.loaded / onUploadProgress.total) * 100
            );
          }
        )

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Documento cargado exitosamente"
          })
          this.$emit('getProceso');
          this.expediente = null;
          this.$v.$reset();
          this.getExpedienteProceso()
          this.showUploadProgress = false;
          this.uploadProgress = 0;
        }

      } catch (error) {
        if (this.$v.$invalid) {
          if (this.expediente === null) {
            this.temporalAlert({
              show: true,
              type: "error",
              message: "Debe de subir un documento"
            })
          }
          }

        this.uploadProgress = 0;
        this.showUploadProgress = false;
      } finally {
      }

    },
    async descargarArchivo() {

      const response = await this.services.PacProcesos.descargarDocumentoProceso({
        ruta: this.documento_cargado
      })
        .catch(() => {
          })
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], this.documentos[0].nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
  },
  created() {
    this.getExpedienteProceso()
  },
};
</script>
