var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-row',[(
      ['resta_porcentaje', 'resta_monto', 'resta_unitaria'].includes(
        _vm.form_enviar?.ctlSubasta?.tipo_operacion
      )
    )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[(
          ['resta_monto', 'resta_unitaria'].includes(
            _vm.form_enviar?.ctlSubasta?.tipo_operacion
          )
        )?_c('v-text-field',{attrs:{"outlined":"","label":"Salto entre ofertas*","prefix":"$","readonly":_vm.isShow},on:{"keypress":function($event){return _vm.validatorKey($event)},"paste":(e) => e.preventDefault()},model:{value:(_vm.form_enviar.saltoOfertas),callback:function ($$v) {_vm.$set(_vm.form_enviar, "saltoOfertas", $$v)},expression:"form_enviar.saltoOfertas"}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.labelMontoInicial,"readonly":_vm.isShow,"prefix":"$"},on:{"keypress":function($event){return _vm.validatorKey($event)},"paste":(e) => e.preventDefault()},model:{value:(_vm.form_enviar.monto_inicial),callback:function ($$v) {_vm.$set(_vm.form_enviar, "monto_inicial", $$v)},expression:"form_enviar.monto_inicial"}})],1),(
      [
        'resta_porcentaje_publicidad',
        'resta_porcentaje',
        'suma_porcentaje',
      ].includes(_vm.form_enviar?.ctlSubasta?.tipo_operacion)
    )?[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',[_vm._v(" Esta subasta será porcentual en base al monto de "+_vm._s(Intl.NumberFormat("en-US", { currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(_vm.form_enviar.monto_inicial))+" ")])])]:_vm._e()],2),(['resta_monto'].includes(_vm.form_enviar?.ctlSubasta?.tipo_operacion))?_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"color":"success","label":`Permitir al proveedor ingresar pujas de forma manual: ${_vm.form_enviar.monto_digitado ? 'Sí' : 'No'}`},model:{value:(_vm.form_enviar.monto_digitado),callback:function ($$v) {_vm.$set(_vm.form_enviar, "monto_digitado", $$v)},expression:"form_enviar.monto_digitado"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }