<template>
  <section>
    <v-row>
      <v-col cols="12" md="6" v-if="
        ['resta_porcentaje', 'resta_monto', 'resta_unitaria'].includes(
          form_enviar?.ctlSubasta?.tipo_operacion
        )
      ">
        <v-text-field outlined v-model="form_enviar.saltoOfertas" label="Salto entre ofertas*" prefix="$"
          @keypress="validatorKey($event)" :readonly="isShow" @paste="(e) => e.preventDefault()" v-if="
            ['resta_monto', 'resta_unitaria'].includes(
              form_enviar?.ctlSubasta?.tipo_operacion
            )
          " />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field outlined v-model="form_enviar.monto_inicial" :label="labelMontoInicial" :readonly="isShow"
          prefix="$" @keypress="validatorKey($event)" @paste="(e) => e.preventDefault()" />
      </v-col>
      <template v-if="
        [
          'resta_porcentaje_publicidad',
          'resta_porcentaje',
          'suma_porcentaje',
        ].includes(form_enviar?.ctlSubasta?.tipo_operacion)
      ">
        <!-- <v-col
          cols="12"
          md="6"
          v-if="
            [
              'suma_porcentaje',
              'resta_porcentaje',
              'resta_porcentaje_publicidad',
            ].includes(form_enviar?.ctlSubasta?.tipo_operacion)
          "
        >
          <v-text-field
            outlined
            v-model="form_enviar.porcentaje"
            label="Porcentaje inicial*"
            @keypress="validatorKey($event)"
            @paste="(e) => e.preventDefault()"
          />
        </v-col> -->
        <v-col cols="12" md="6">
          <h3>
            Esta subasta será porcentual en base al monto de
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(form_enviar.monto_inicial)
            }}
          </h3>
        </v-col>
      </template>
    </v-row>
    <v-row v-if="['resta_monto'].includes(form_enviar?.ctlSubasta?.tipo_operacion)">
      <v-col>
        <v-switch color="success" v-model="form_enviar.monto_digitado"
          :label="`Permitir al proveedor ingresar pujas de forma manual: ${form_enviar.monto_digitado ? 'Sí' : 'No'}`" />
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "SingularSubasta",
  methods: {
    validatorKey(e) {
      if (window.event.keyCode >= 48 && window.event.keyCode <= 57) return;
      if (window.event.keyCode === 46) return;
      e.preventDefault();
    },
  },
  computed: {
    ...mapState("configuracionSalas", ["form_enviar", "montoInicial"]),
    ...mapState("etapaSubastaInversaStore", ["form", "isShow"]),
    labelMontoInicial() {
      return ["resta_unitaria"].includes(
        this.form_enviar.ctlSubasta?.tipo_operacion
      )
        ? "Precio unitario"
        : "Monto inicial*";
    },
  },
  watch: {
    "form_enviar.ctlSubasta": {
      handler: function (val) {
        if (val?.tipo_operacion === "resta_monto") {
          this.form_enviar.monto_digitado = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
/* Your component styles go here */
</style>
