<template>
  <section>
    <data-table-component
        class="mb-3"
        :headers="HEADERS_CONST"
        :items="dataProp"
        inhabilitar_paginacion
        :tiene_paginacion="false"
    >
      <template v-slot:[`item.username`]="{ item }">
        {{ item?.cargado_por?.primer_nombre }}
        {{ item?.cargado_por?.primer_apellido }}
      </template>
      <template v-slot:[`item.fecha_emision`]="{ item }">
        {{ FormatDatePrt(item.fecha_emision) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="
                descargarArchivoProceso({
                  ruta_documento: item.ruta_archivo,
                  disk: disk(item.tipo_contrato)
                })
              "
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar</span>
        </v-tooltip>
      </template>
    </data-table-component>
  </section>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import {mapActions} from "vuex";

export default {
  name: "TabDocumentosComponent",

  components: {
    DataTableComponent,
  },

  props: {
    dataProp: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    // constants
    HEADERS_CONST: [
      {
        text: "Tipo de documento",
        value: "tipo_contrato",
        align: "center",
      },
      {
        text: "Usuario que registra",
        value: "username",
        align: "center",
      },
      {
        text: "Fecha de creación",
        value: "fecha_emision",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
  }),

  methods: {
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
    disk(tipo_contrato) {
      switch (tipo_contrato) {
        case 'Contrato':
        case 'Orden de compra':
        case 'Acta de liquidación':
        case 'Actas de recepción total ':
        case 'Otros':
        case 'Orden de inicio':
        case 'Garantía':
          return 'contratos'
        default:
          return 'local'
      }
    },
  },
};
</script>
